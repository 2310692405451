/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion } from "framer-motion"
import { Link } from "gatsby"

import Date from "../components/date"
import transition from "../utils/framer-transition"

const textMotionVariants = {
  initial: { x: -60, opacity: 0 },
  enter: { x: 0, opacity: 1, transition },
  exit: {
    x: -60,
    opacity: 0,
    transition: { ...transition },
  },
}

const BlogMeta = ({ dateCreated, slug, casualName }) => {
  return (
    <motion.p
      sx={{
        marginBottom: "0px",
        fontSize: 0,
      }}
      variants={textMotionVariants}
    >
      <Date format="Do MMMM YYYY" dateCreated={dateCreated} />
    </motion.p>
  )
}

export default BlogMeta
